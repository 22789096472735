import React, { useEffect, useRef } from 'react';
import PartyMetaData from './PartyMetadata';
import useOutsideElement from '../../../utilities/useOutsideElement';
import createNotification from '../../../../Settings/Utilities/CreateNotification';
import CustomScrollbar from '../../../../Common/CustomScrollbar';
import normalize from '../../../utilities/normalizeString';

const AdditionalOptions = (props) => {
  const { optionsToRender, chart, type, options, openDropdownState, scrollBarPosition } = props;

  const {
    openOtherDropdown: openOptions,
    setOpenOtherDropdown: setOpenOptions,
    dropdownStyles,
    setDropdownStyles,
  } = openDropdownState ?? {};
  const closeOptions = () => {
    setOpenOptions(false);
  };
  const optionsContainer = useRef();
  useOutsideElement(optionsContainer, closeOptions);

  const optionsSelected = () => {
    let number = 0;
    for (let i = 0; i < optionsToRender.length; i++) {
      let item = optionsToRender[i];
      if (chart.isDatasetVisible(item.datasetIndex)) {
        number += 1;
      }
    }
    return number;
  };

  let selectedOptions = optionsSelected();

  const selectDeselectOptions = (hidden, { setSelectedCategories, selectedCategories }) => {
    optionsToRender.forEach((item) => {
      if (hidden) {
        chart.hide(item.datasetIndex);
      } else {
        chart.show(item.datasetIndex);
      }
    });
    let newcategories = selectedCategories.filter(
      (item) => !optionsToRender.map((option) => option.text).includes(item)
    );
    if (hidden) {
      setSelectedCategories(newcategories);
    } else {
      setSelectedCategories([...newcategories, ...optionsToRender.map((option) => option.text)]);
    }

    if (options.onClickFunction) {
      options.onClickFunction(chart);
    }
  };

  useEffect(() => {
    if (!!scrollBarPosition) {
      setOpenOptions(false);
    }
  }, [scrollBarPosition]);

  useEffect(() => {
    const { top, left } = optionsContainer.current?.getBoundingClientRect() ?? {};
    if (setDropdownStyles) {
      setDropdownStyles({
        position: 'fixed',
        top: `${top + 35}px`,
        left: left + 8,
      });
    }
  }, [openOptions]);

  return (
    <div className='position-relative' ref={optionsContainer}>
      {optionsToRender.length > 0 && (
        <button
          className='general-button p-0 party-filters-more-button'
          onClick={() => {
            setOpenOptions(!openOptions);
          }}
        >
          <span className='dropdown-item-element'>
            Other <span className='text-light-blue'>{selectedOptions > 0 ? `(${selectedOptions})` : ''}</span>
          </span>
        </button>
      )}
      {openOptions && (
        <div className='login-navbar trends-party-box' style={dropdownStyles}>
          <CustomScrollbar
            className={'simple-scrollbar indented-scrollbar-small pl-0'}
            maximalThumbYSize={100}
            style={{ height: '210px' }}
          >
            <div className=''>
              <button
                className='general-button border-bottom mb-3 d-block w-100 text-light-blue text-left select-all-button'
                onClick={() => {
                  selectDeselectOptions(selectedOptions > 0, options);
                }}
              >
                {selectedOptions > 0 ? 'Deselect' : 'Select'} all
              </button>
            </div>
            <div className='pb-2 pl-3'>
              {optionsToRender.map((item) => (
                <ListItem
                  key={`item-${options.customGraph ? 'custom' : 'main'}-${item.text}`}
                  item={item}
                  chart={chart}
                  type={type}
                  options={options}
                  additionalOption={true}
                />
              ))}
            </div>
          </CustomScrollbar>
        </div>
      )}
    </div>
  );
};

const ListItem = (props) => {
  const { item, chart, type, options, additionalOption } = props;
  const createBackground = () => {
    if (typeof item.fillStyle === 'string') {
      return type === 'pie'
        ? `${item.fillStyle?.replace('0.8', '0.5')}`
        : options.typeOfChart === 'Line'
          ? `${item.fillStyle?.replace('0.1', '0.5')}`
          : `${item.fillStyle?.replace(')', ', 0.5)')}`;
    } else {
      return '';
    }
  };
  return (
    <li
      className={'legend-container-select-box'}
      onClick={() => {
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          let toggleVisibility = true;
          let isDatasetVisible = chart.isDatasetVisible(item.datasetIndex);
          if (isDatasetVisible) {
            let datasets = chart?.config?.data?.datasets;
            let visibleDatasets = datasets.filter((item, index) => chart.isDatasetVisible(index));
            if (visibleDatasets.length === 1) {
              toggleVisibility = false;
              createNotification('danger', 'You must have at least 1 resource selected');
            }
          }
          if (toggleVisibility) {
            chart.setDatasetVisibility(item.datasetIndex, !isDatasetVisible);
            const { selectedCategories, setSelectedCategories } = options;
            if (setSelectedCategories) {
              let chartLabels = chart?.options?.plugins?.legend?.labels?.generateLabels(chart);
              let newCategories = !!selectedCategories ? [...selectedCategories] : chartLabels.map((item) => item.text);
              if (isDatasetVisible) {
                let position = newCategories.indexOf(item.text);
                newCategories.splice(position, 1);
              } else {
                newCategories.push(item.text);
              }
              setSelectedCategories(newCategories);
            }
          }
        }
        chart.update();
        if (options.onClickFunction) {
          options.onClickFunction(chart, item);
        }
      }}
      style={{
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        marginRight: '20px',
        marginBottom: '10px',
        fontSize: '0.925em',
      }}
    >
      <span
        className={`legend-item ${item.hidden ? '' : 'legend-selected'} ${normalize(item.text)}`}
        style={{
          background: createBackground(),
          border: `1px solid ${item.strokeStyle}`,
          borderRadius: `3px`,
          color: item.strokeStyle,
          display: 'inline-block',
          height: '20px',
          marginRight: '10px',
          width: '20px',
          minWidth: '20px',
          marginTop: additionalOption ? '1px' : '0px',
        }}
      />
      <p
        style={{
          color: item.fontColor,
          margin: 0,
          padding: 0,
        }}
      >
        {item.text}
      </p>
    </li>
  );
};

const MentionsChartLabels = (props) => {
  const { chart, chartDataOptionSelected, customGraph, openDropdownState, scrollBarPosition, data } = props;
  /*AE: Past method, left here in case it's needed
  const items = sortPositionLabels(chart.options.plugins.legend.labels.generateLabels(chart));
  */
  const items = !!data
    ? data?.map((item, index) => {
        const { label, backgroundColor, hidden } = item;
        return {
          datasetIndex: index,
          fillStyle: backgroundColor[0],
          fontColor: '#666',
          hidden: hidden,
          strokeStyle: backgroundColor[0],
          text: label,
        };
      })
    : [];
  const partyOptions = chartDataOptionSelected === 'Party';
  const itemsToRender = partyOptions ? items.filter((item) => PartyMetaData.showParties.includes(item.text)) : items;

  return (
    <ul style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: 0, padding: 0 }}>
      {itemsToRender.map((item) => {
        return (
          <ListItem
            key={`item-${customGraph ? 'custom' : 'main'}-${item.text}`}
            item={item}
            chart={chart}
            type={chart.config.type}
            options={props}
          />
        );
      })}
      {!!partyOptions && (
        <>
          <AdditionalOptions
            optionsToRender={items.filter((item) => !PartyMetaData.showParties.includes(item.text))}
            chart={chart}
            type={chart.config.type}
            options={props}
            openDropdownState={openDropdownState}
            scrollBarPosition={scrollBarPosition}
          />
        </>
      )}
    </ul>
  );
};

export { MentionsChartLabels };
