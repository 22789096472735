import React, { useEffect, useState } from 'react';
import useTopicOrClientWord from '../../../Dashboard/hooks/useTopicOrClientWord';
import normalize from '../../../Dashboard/utilities/normalizeString';
import { useCollapse } from 'react-collapsed';

const UpcomingEventsList = (props) => {
  const {
    allUpcomingEvents,
    createUpcomingEvent,
    deleteUpcomingEvent,
    allowEventNewsletters,
    onlySendEmailEventsRelevant,
    onlySendRelevant,
    stateExpanded,
  } = props;

  const { transformTopicOrClientWord, newAgencyPlan: agencyPlanNew } = useTopicOrClientWord();
  const newAgencyPlan = props?.newAgencyPlan ?? agencyPlanNew;
  const { expandedStates, modifyExpandedStates } = stateExpanded ?? {};
  const isExpanded = expandedStates?.['upcomingEvents'] ?? true;
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const numberOfAlerts = allUpcomingEvents?.filter((item) => item.type !== 'MajorAnnouncements' && !!item?.id)?.length;

  const [showNumber, setShowNumber] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowNumber(!isExpanded);
    }, 400);
  }, [isExpanded]);

  return (
    <>
      <div
        className={`padding-topic-component border-bottom border-top py-3 ${newAgencyPlan ? 'bg-main-blue text-white pointer' : 'bg-salmon'}`}
        {...getToggleProps({
          onClick: (e) => {
            if (newAgencyPlan && modifyExpandedStates) {
              modifyExpandedStates({ property: 'upcomingEvents', value: !isExpanded });
            } else {
              e.preventDefault();
            }
          },
        })}
      >
        <div className='d-flex'>
          <span className='icon-calendar mr-2' style={{ marginTop: '2px' }}></span>
          <div className='pl-2'>
            <h3 className={`my-0  title-h4${newAgencyPlan ? '' : '-bold'}`}>
              <span
                className={`${newAgencyPlan ? `dropdown-item-element dropdown-item-element ${isExpanded ? 'open' : ''}` : ''} `}
              >
                Based on upcoming events
              </span>
            </h3>
            {newAgencyPlan && !isExpanded && showNumber && (
              <p className='d-block mb-0 paragraph-p3'>
                {numberOfAlerts} active alert{numberOfAlerts === 1 ? '' : 's'}
              </p>
            )}
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className='padding-topic-component pb-5'>
          <div className='px-lg-2 px-xl-4'>
            <div className='pt-4'>
              {allUpcomingEvents
                ?.filter((item) => item.type !== 'MajorAnnouncements')
                ?.map((item) => {
                  const { id, type, description, dataTip, name } = item;
                  const idConditional = id !== null && id !== undefined;
                  return (
                    <div className='upcoming-event-main-container ' key={`item-${normalize(type)}`}>
                      <div className='row mx-0 justify-content-between align-items-center mb-3'>
                        <div>
                          <p className='topics-item-name_alert--text'>
                            {name}
                            {!allowEventNewsletters && (
                              <img
                                src={`${process.env.REACT_APP_CDNURL}/images/padlock.svg`}
                                className='padlock-title-item'
                                alt='padlock feature blocked'
                                data-tooltip-content={`Email alerts are available on Pro and Mogul`}
                                data-tooltip-id={'settings-tooltip'}
                              />
                            )}
                          </p>
                        </div>
                        <div className={`text-right`}>
                          <button
                            className={`email-alerts-button-padding ml-2 general-button outline-button main-light-text paragraph-p3 rounded-button px-3 bg-white`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (allowEventNewsletters) {
                                if (idConditional) {
                                  deleteUpcomingEvent(item);
                                } else {
                                  createUpcomingEvent(item);
                                }
                              }
                            }}
                          >
                            {idConditional ? 'Active' : 'Paused'}
                            <span className={`ml-2 toggle-button-${idConditional ? 'active' : 'inactive'}`}>
                              <span className='toggle-button'></span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <p className='alert-list-text-item mb-2 frequency-alert-icon'>
                        <span className='icon-clock'></span>Sent on {description}
                      </p>
                      <p className='alert-list-text-item resources-alert-icon'>
                        <span className='icon-description'></span>
                        {dataTip}{' '}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className='box-rounded box-grey send-diary-emails'>
              <div className='checkbox-item mb-4 my-sm-0'>
                <input
                  id='diary-emails'
                  type='checkbox'
                  onChange={(e) => {
                    if (allowEventNewsletters) {
                      onlySendEmailEventsRelevant(e, !onlySendRelevant);
                    }
                  }}
                  checked={onlySendRelevant}
                />
                <label htmlFor='diary-emails' className='font-weight-normal no-select-item'>
                  Only send diary emails that contain events relevant to me, based on my{' '}
                  {newAgencyPlan ? 'clients' : transformTopicOrClientWord({ plural: true })}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MajorAnnouncements
        allUpcomingEvents={allUpcomingEvents}
        allowEventNewsletters={allowEventNewsletters}
        deleteUpcomingEvent={deleteUpcomingEvent}
        createUpcomingEvent={createUpcomingEvent}
        stateExpanded={stateExpanded}
        newAgencyPlan={newAgencyPlan}
      />
    </>
  );
};

const MajorAnnouncements = (props) => {
  const {
    allUpcomingEvents,
    allowEventNewsletters,
    deleteUpcomingEvent,
    createUpcomingEvent,
    stateExpanded,
    newAgencyPlan,
  } = props;

  const majorAnnouncementAlert = allUpcomingEvents?.find((item) => item.type === 'MajorAnnouncements');
  const { expandedStates, modifyExpandedStates } = stateExpanded ?? {};
  const isExpanded = expandedStates?.['majorAnnouncements'] ?? true;
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const [showNumber, setShowNumber] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowNumber(!isExpanded);
    }, 400);
  }, [isExpanded]);

  return (
    <>
      <div
        className={`padding-topic-component border-bottom py-3 ${newAgencyPlan ? 'bg-main-blue text-white pointer' : 'bg-salmon'}`}
        {...getToggleProps({
          onClick: (e) => {
            if (newAgencyPlan && modifyExpandedStates) {
              modifyExpandedStates({ property: 'majorAnnouncements', value: !isExpanded });
            } else {
              e.preventDefault();
            }
          },
        })}
      >
        <div className='d-flex'>
          <span
            className='icon-warning-circle-thick mr-2 title-h3'
            style={{ marginTop: '2px', marginLeft: '-4px' }}
          ></span>
          <div className='pl-2'>
            <h3 className={`my-0 title-h4${newAgencyPlan ? '' : '-bold'}`}>
              <span
                className={`${newAgencyPlan ? `dropdown-item-element dropdown-item-element ${isExpanded ? 'open' : ''}` : ''} `}
              >
                Based on major announcements
              </span>
            </h3>
            {newAgencyPlan && !isExpanded && showNumber && (
              <p className='d-block mb-0 paragraph-p3'>
                {!!majorAnnouncementAlert?.id ? 1 : 0} active alert{!!majorAnnouncementAlert?.id ? '' : 's'}
              </p>
            )}
          </div>
        </div>
      </div>

      <div {...getCollapseProps()}>
        <div className='padding-topic-component'>
          <div className='px-lg-2 px-xl-4'>
            <div className='pt-4'>
              <div className='row mx-0 justify-content-between align-items-center mb-3'>
                <div>
                  <p className='topics-item-name_alert--text'>All major announcements</p>
                </div>
                <div className={`text-right`}>
                  <button
                    className={`email-alerts-button-padding ml-2 general-button outline-button main-light-text paragraph-p3 rounded-button px-3 bg-white`}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (allowEventNewsletters) {
                        if (majorAnnouncementAlert?.id) {
                          deleteUpcomingEvent(majorAnnouncementAlert);
                        } else {
                          createUpcomingEvent(majorAnnouncementAlert);
                        }
                      }
                    }}
                  >
                    {majorAnnouncementAlert?.id ? 'Active' : 'Paused'}
                    <span className={`ml-2 toggle-button-${majorAnnouncementAlert?.id ? 'active' : 'inactive'}`}>
                      <span className='toggle-button'></span>
                    </span>
                  </button>
                </div>
              </div>
              <p className='alert-list-text-item mb-2 frequency-alert-icon'>
                <span className='icon-clock'></span>Sent as soon as it happens
              </p>
              <p className='alert-list-text-item resources-alert-icon'>
                <span className='icon-description'></span>Includes announcements such as King's Speech, Budget, recesses
                and ministerial appointments
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingEventsList;
